































































































































































import { InvitationSentComponent } from '@/popupcomponents/invitationcomponent/InvitationComponent';
export default InvitationSentComponent;
